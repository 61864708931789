.footer {
  @apply text-gray-600;
  font-size: 1.2rem;

  .footer__link {
    &:hover {
      @apply text-gray-800;
    }

    &:not(:last-child)::after {
      content: "|";
      margin: 0.5rem;
    }
  }
}
