//? set your brand colors

$white: #fff;

$light-grey: #fafafa;
$grey: #d7d7d7;

$black-soft: #333;
$black: #09090e;

$teal: #00b8a5;
$pink: #fff0f5;
$yellow: #fffacd;

$title-font-size: 4.25rem;
$title-font-size-mobile: 2.5rem;

$subtitle-font-size: 2.75rem;
$subtitle-font-size-mobile: 3rem;

$body-font-size: 1.4rem;
$body-font-size-mobile: 1.6rem;

$font-heading: "Rock Salt", cursive;
$font-body: "Poppins", sans-serif;

//? override for project clarity

$color-primary: $teal;
$color-secondary: $pink;
$color-tertiary: $yellow;
