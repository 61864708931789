html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-size: $body-font-size-mobile;
  color: $black-soft;

  @screen lg {
    font-size: $body-font-size;
  }
}

h1 {
  font-family: $font-heading, serif;
  font-size: 1.7rem;
  font-weight: 400;
}
