.product-meta {
  .product-meta__offer {
    font-family: $font-body;
    font-size: $subtitle-font-size-mobile;
    font-weight: 500;
    color: $black-soft;

    @screen md {
      font-size: $subtitle-font-size;
    }
  }

  .product-meta__offer-accent {
    color: $color-primary;
    font-weight: 600;
  }

  .product-meta__offer-subtitle {
    color: $black-soft;
    font-weight: 500;
    font-size: 1.8rem;
  }

  .product-meta__benefits {
    line-height: 2;
    margin-left: 1rem;
    margin-bottom: 3rem;

    li {
      margin-bottom: 1rem;
      margin-left: 2rem + 0.7rem;
    }

    li::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.7rem;
      width: 2rem;
      height: 2rem;
      background-image: url("images/check.svg");
      background-size: contain;
      margin-left: calc(-2rem - 0.7rem);
    }
  }

  .checkout-button {
    padding: 2rem;
    background: $color-primary;
    box-shadow: 0 4px 0 -1px #00886d;
    border: 1px solid #00886d47;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    border-radius: 1rem;
    width: 100%;
    font-weight: 600;
    font-size: 2.8rem;

    &:hover,
    &:active {
      background: darken($color-primary, 3);
    }

    @screen md {
      font-size: 3.8rem;
    }
  }

  .submit-button {
    padding: 1.5rem 3rem;
    background: $color-primary;
    box-shadow: 0 4px 0 -1px #00886d;
    border: 1px solid #00886d47;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    border-radius: 1rem;
    width: auto;
    font-weight: 600;
    font-size: 2rem;

    &:hover,
    &:active {
      background: darken($color-primary, 3);
    }

    @screen md {
      font-size: 2rem;
    }
  }
}
