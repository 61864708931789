.logo-title {
  font-size: $title-font-size-mobile;
  color: $black-soft;
  line-height: 2;

  @screen md {
    font-size: $title-font-size;
  }
}

.logo {
  .logo__image {
    width: 25rem;
  }
}

.signout-button {
  padding: 1rem;
  border: 1px solid $color-primary;
  color: $color-primary;
  border-radius: 1rem;
  font-size: 1.6rem;

  &:hover,
  &:active {
    background: darken($color-primary, 3);
    color: #ffffff;
  }

  @screen md {
    padding: 1rem 2rem;
    font-size: 1.6rem;
  }
}
